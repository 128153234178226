import React from 'react';
import { IHeroBannerItem } from './utils';
import { useSelector } from 'react-redux';
import { isMobileLayout } from 'services/device';
import useHeroBannerLocalizeStrings from './use-hero-banner-localize-strings';
import { isUrl } from '../../../url-utils';

interface IUseHeroBannerParams {
  disableImageBleed: boolean;
  heroBannerItem: IHeroBannerItem;
}

interface IUseHeroBannerResponse {
  background: IHeroBannerItem['data']['background'];
  backgroundHref: string;
  backgroundHrefTarget: '_blank' | '_self';
  backgroundImageHeight: number;
  description: string;
  hasBackgroundImage: boolean;
  hasContentOnHeroBanner: boolean;
  primaryCtaText: string;
  secondaryCtaText: string;
  setBackgroundImageHeight: (height: number) => void;
  title: string;
}

const useHeroBanner = ({ heroBannerItem, disableImageBleed }: IUseHeroBannerParams): IUseHeroBannerResponse => {
  const [backgroundImageHeight, setBackgroundImageHeight] = React.useState(0);

  const isMobile = useSelector(isMobileLayout);
  const heroBanner = heroBannerItem.data;

  // if Primary CTA is off, clicking anywhere on the image banner links to the link field
  // Otherwise, clicking anywhere on the image banner (except Secondary CTA button)
  // links to the URL set in the Primary CTA
  const { href: backgroundHref, target: backgroundHrefTarget } = React.useMemo(() => {
    let href = heroBanner.background.href;
    let target: '_blank' | '_self' = '_self';

    if (heroBanner.primaryCta.show && heroBanner.primaryCta.behavior.type === 'openLink') {
      href = heroBanner.primaryCta.behavior.url;
      target = heroBanner.primaryCta.behavior.openInNewTab ? '_blank' : '_self';
    } else if (isUrl(href)) {
      target = '_blank';
    }

    return {
      href,
      target,
    };
  }, [heroBanner.primaryCta.show, heroBanner.primaryCta.behavior, heroBanner.background.href]);

  // admin can set to bleed the image but we also need to consider:
  // - Next and previous editor blocks (where the disabledImageBleed comes in)
  const background = React.useMemo(() => {
    return {
      ...heroBanner.background,
      bleed: !disableImageBleed && heroBanner.background.bleed,
    };
  }, [heroBanner.background, disableImageBleed]);

  const hasContentOnHeroBanner = React.useMemo(() => {
    return (
      heroBanner.title.show ||
      heroBanner.description.show ||
      heroBanner.primaryCta.show ||
      heroBanner.secondaryCta.show
    );
  }, [heroBanner]);

  const hasBackgroundImage = React.useMemo(() => {
    return Boolean((background.desktop && !isMobile) || (background.mobile && isMobile));
  }, [background.desktop, background.mobile, isMobile]);

  const { description, primaryCtaText, secondaryCtaText, title } = useHeroBannerLocalizeStrings(heroBannerItem);

  return {
    setBackgroundImageHeight,
    backgroundImageHeight,
    backgroundHref,
    background,
    hasContentOnHeroBanner,
    hasBackgroundImage,
    description,
    primaryCtaText,
    secondaryCtaText,
    title,
    backgroundHrefTarget,
  };
};

export default useHeroBanner;
